import React, { Component } from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import axios from 'axios';
import loopIcon from '../images/loop-icon.svg';
import { URL } from '../utils/utils';
import shareLinkIcon from '../images/share-link-icon.svg';
import arrowDownIcon from '../images/arrow-down.svg';
import arrowUpIcon from '../images/arrow-up.svg';
import { Link } from 'gatsby'


const H3 = styled.h3`
  font-size: 30px;

  @media(max-width: 1184px) {
    font-size: 18px;
  }
`;

const H5 = styled.h5`
  font-size: 18px;
`;

const AccordionStyledWrapper = styled.div`
  margin-bottom: 120px;

  @media(max-width: 1184px) {
    padding: 0px 2.5%;
    margin-bottom: 0;
  }
`;

const TitleSection = styled.div`
  margin: 160px 0 40px;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;

  @media(max-width: 1184px) {
    align-items: flex-start;
    text-align: left;
    margin: 0px auto;
  }
`;

const TagSection = styled.div`
  display: flex;
  align-items: center;
`;

const Tag = styled.h1`
  font-size: 32px;
  line-height: 78px;
  margin-top: 1px;

  @media(max-width: 1184px) {
    line-height: 45px;
  }
`;

const LineLeft = styled.div`
  height: 1px;
  width: 194px;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  margin-right: 20px;

  @media(max-width: 1184px) {
    display: none;
  }
`;

const LineRight = styled.div`
  height: 1px;
  width: 194px;
  background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  margin-left: 20px;

  @media(max-width: 1184px) {
    display: none;
  }
`;

const DiseaseName = styled.h2`
  margin: 0;
  font-size: 18px;
  line-height: initial;
  color: #fff;

  @media(max-width: 1184px) {
    font-size: 18px;
  }
`;

const DiseaseArrow = styled.img`
  color: #fff;
  font-size: 23px;
`;

const SearchInputWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
`;

const SearchInput = styled.div`
  border: none;
  font-size: 16px;
  align-self: baseline;

  input {
    background: none;
    border: none;
    border-bottom: 1px solid #fff;
    color: #fff;
    font-size: 16px;
    margin-left: 20px;
    padding: 4px 0;

    ::placeholder {
      color: #fff;
    }
  }

  @media(max-width: 1184px) {
    display: flex;
    width: 100%;

    input {
      width: 100%;
    }
  }
`;

const LoopImage = styled.img`
  vertical-align: middle;
`;

const WikiText = styled.p`
  text-align: center;
  color: #c8d2ff;
  margin-bottom: 30px;

  @media(max-width: 1184px) {
    text-align: left;
  }
`;

const Alphabet = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(35px, 1fr));
  grid-gap: 6px;
  margin-bottom: 14px;
`;

const Accordion = styled.div``;

const AccordionItem = styled.div`
  cursor: pointer;
  width: 100%;
  background-color: ${props => props.isOpened ? '#ff413d' : '#203bbc'};
  padding: 24px 40px;
  border-radius: 5px;
  margin-bottom: 14px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media(max-width: 1184px) {
    text-align: center;
    border-radius: 15px;
    padding: 20px;
    background-color: ${props => props.isOpened ? '#1d39c0' : '#2a4eff'};
  }
`;

const AccordionItemContent = styled.div`
  margin-top: 57px;
  padding: 0 40px;

  @media(max-width: 1184px) {
    margin-top: 15px;
    padding: 0 15px;
  }
`;

const AccordionItemContentAnimate = posed(AccordionItemContent)({
  visible: {
    applyAtStart: { display: 'block' },
    opacity: 1,
    transition: { duration: 2000 },
  },
  hidden: {
    opacity: 0,
    applyAtEnd: { display: 'none' }
  }
});

const ShareLinkButton = styled.button`
  border: 1px solid #fff;
  padding: 8px;
  display: flex;
  margin: 14px 0;
  border-radius: 5px;
  background: transparent;
  display: flex;
  align-items: center;

  p {
    color: #fff;
  }

  img {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }

  @media(max-width: 1184px) {
    width: 160px;
    justify-content: center;
    font-size: 11px;
    color: #fff;
    margin: 0;
    margin-bottom: 16px;
  }
`;

const SingleLetter = styled.div`
  padding: 6px 12px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  pointer-events: ${props => props.isDiseaseOnThatLetter ? 'all' : 'none'};
  background-color: ${props => props.checked ? '#ff413d' : '#203bbc'};
  background-color: ${props => props.isDiseaseOnThatLetter ? '203bbc' : 'transparent'};
`;

const AboutDiseaseAccordion = styled.div`
  display: flex;
  margin-bottom: 43px;

  h2 {
    line-height: initial;
    grid-area: title;
  }

  .imageContainer {
    margin-right: 50px;
    grid-area: img;
    min-width: 270px;

    img {
      min-width: initial;
      width: 100%;
      height: auto;
      border-radius: 5px;
    }
  }

  @media(max-width: 1184px) {
    margin-bottom: 20px;
    display: grid;
    grid-template-areas:
      "title"
      "img";

    h2 {
      margin-bottom: 12px;
    }

    .imageContainer {
      margin: 12px 0 0;
    }
  }
`;


const AuthorInfoWrapper = styled.div`
  margin: 40px 0;
`;

const AuthorData = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
`;

const AuthorImageWrapper = styled.div`
  width: 150px;
  height: 200px;
  margin-right: 30px;
  overflow: hidden;
  border-radius: 20px 5px 5px 5px;
  display: flex;
  justify-content: center;
  
  @media(max-width: 1184px) {
    width: 120px;
    height: 160px;
    border-radius: 15px 4px 4px 4px;
  }
`;

const AuthorImage = styled.img`
  height: 100%;
  width: auto;
`;

const AuthorText = styled.p`
  margin: 20px 0;
  max-width: 500px;
`;

const Button = styled.button`
  padding: 13px 30px;
  font-size: 16px;
  border-radius: 5px;
  background-color: ${props => props.red ? '#ff413d' : '$fff'};
  color: ${props => props.red ? '#fff' : '#14277d'};
  margin-right: 30px;
  cursor: pointer;

  @media(max-width: 1184px) {
    padding: 11px 24px;
    font-size: 11px;
    color: ${props => props.red ? '#fff' : '#0a144b'};
    margin-bottom: 15px;
    margin-right: 15px;
  }
`;


class DiseasesSection extends Component {
  state = {
    currentDiseaseId: 0,
    animate: false,
    intervalId: null,
    displayCV: false,
    checkedItem: null,
    diseases: null,
    openedDisease: null,
    openedShortDiseaseDescription: null,
    diseaseLetters: null,
    alphabet: new Map([
      ['A', false], ['B', false], ['C', false], ['D', false], ['E', false], ['F', false], ['G', false], ['H', false], ['I', false], ['J', false], ['K', false], ['L', false], ['M', false], ['N', false], ['O', false], ['P', false], ['Q', false], ['R', false], ['S', false], ['T', false], ['U', false], ['V', false], ['X', false], ['Y', false], ['Z', false]
    ]),
    checkedLetter: 'A',
    searchFilter: '',
    isAlphabetUpdated: false,
    isLinkCopied: false,
    displayHeroElement: false
  }

  componentDidMount() {
    const search = sessionStorage.getItem('search')
    if (search) {
      console.log('search from session storage', search)
      this.setState({
        searchFilter: search,
        checkedLetter: null,
      })
      axios.get(`${URL}/api/diseases?query=${search}`)
        .then(response => {
          this.setState({ diseases: response.data })
        })
        .catch(err => console.log(err));
    } else {
      axios.get(`${URL}/api/diseases?letter=${this.state.checkedLetter}`)
        .then(response => this.setState({ diseases: response.data }))
        .then(() => {
          const urlParams = new URLSearchParams(window.location.search);
          if (urlParams.get('article') && this.state.diseases) {
            this.openDiseaseAndScroll(urlParams.get('article'));
          };
        })
        .catch(err => console.log(err));
    }

    axios.get(`${URL}/api/diseases/letters`)
      .then(response => {
        this.setState({ diseaseLetters: response.data })
      })
      .then(() => {
        this.state.diseaseLetters.forEach(diseaseLetter => {
          this.setState(prevState => ({
            alphabet: prevState.alphabet.set(diseaseLetter.letter.toUpperCase(), true)
          }))
        })
      })
      .then(() => this.setState({ isAlphabetUpdated: true }))
      .catch(err => console.log(err));

    const urlParams = new URLSearchParams(window.location.search).get('scrollPosition');

    if (urlParams) {
      this.scrollToSection(urlParams);
    }

    this.setState({
      displayHeroElement: true
    }, () => {
      let intervalId = setInterval(this.timer, 10000);
      this.setState({ intervalId});
    })
  }

  scrollToSection = (clickedItem) => {
    if (clickedItem === 'homeAnchor') {
      return window.scrollTo(0, 0);
    }
    const element = document.getElementById(clickedItem);
    document.getElementById(clickedItem).scrollIntoView(true);
    setTimeout(() => {
      element.scrollIntoView(true)
    }, 500);
  }

  animateDiseaseChange = (id) => {
    this.setState({
      animate: true
    });

    setTimeout(() => {
      this.setState({
        animate: false,
        currentDiseaseId: id
      })
    }, 600)
  }

  timer = () => {
    const newDiseaseId = this.state.currentDiseaseId + 1;
    if (newDiseaseId >= 6) {
      this.animateDiseaseChange(0);
    } else {
      this.animateDiseaseChange(newDiseaseId);
    }
  }

  accordionChangeHandler = (accordion, diseaseSlug) => {
    const isCurrentDiseaseBeenClicked = this.state[accordion] === diseaseSlug ? null : diseaseSlug;
    this.setState({
      [accordion]: null,
      isLinkCopied: false
    });

    setTimeout(() => {
      this.setState({ [accordion]: isCurrentDiseaseBeenClicked });
    }, 300);

    if(isCurrentDiseaseBeenClicked) {
      setTimeout(() => {
        const openedAccordionItem = document.getElementById(diseaseSlug).getBoundingClientRect().top + window.scrollY - 70;
        window.scrollTo({
          top: openedAccordionItem,
          behavior: 'smooth'
        });
      }, 400);
    } else {
      setTimeout(() => {
        const accordionSection = document.getElementById('mehrErfahren').getBoundingClientRect().top + window.scrollY - 70;
        window.scrollTo({
          top: accordionSection,
          behavior: 'smooth'
        });
      }, 400);
    }
  }

  updateLetterFilter = letter => {
    let filteredLettersUrl = `${URL}/api/diseases?`;
    const selectedLetter = this.state.checkedLetter === letter ? null : letter;
    this.setState({
      searchFilter: '',
      checkedLetter: selectedLetter}, () => {
      filteredLettersUrl += this.state.checkedLetter ? `letter=${letter}&` : '';

      axios.get(filteredLettersUrl)
        .then(response => {
          this.setState({ diseases: response.data })
        })
        .catch(err => console.log(err));
    })
  }

  copyLinkToAccordionItem = (diseaseSlug) => {
    const pageOffset = window.pageYOffset;
    const hostUrl = window.location.origin;
    const textField = document.createElement('textarea');
    textField.innerText = `${hostUrl}/wiki?article=${diseaseSlug}`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    window.scrollTo(0, pageOffset);
    this.setState({isLinkCopied: true});
  }

  handleChange = event => {
    let searchUrl = `${URL}/api/diseases?query=`;
    this.setState({
      checkedLetter: null,
      searchFilter: event.target.value
    }, () => {
      searchUrl += this.state.searchFilter;
      axios.get(searchUrl)
        .then(response => {
          this.setState({ diseases: response.data })
        })
        .catch(err => console.log(err));
    });
  }

  openDiseaseAndScroll = diseaseSlug => {
    const firstLetterOfDisease = diseaseSlug[0].toUpperCase();
    let scrollOffset = 20;
    if(window.innerWidth > 1184) {
      scrollOffset = 95;
    }
    this.setState({
      openedDisease: diseaseSlug,
      checkedLetter: firstLetterOfDisease,
      searchFilter: '',
      isLinkCopied: false
    }, () => {
      axios.get(`${URL}/api/diseases?letter=${this.state.checkedLetter}`)
        .then(response => this.setState({ diseases: response.data }))
        .then(() => {
          const openedDisease = document.getElementById(`${diseaseSlug}`).offsetTop
          window.scrollTo({
            top: openedDisease + scrollOffset,
            behavior: 'smooth'
          });
        })
        .catch(err => console.log(err));
    });
  }

  scrollToContactSection = () => {
    let scrollOffset = 30;
    if (window.innerWidth > 1184) {
      scrollOffset = 95;
    }
    const contact = document.getElementById('contact').offsetTop;
    window.scrollTo({
      top: contact + scrollOffset,
      behavior: 'smooth'
    })
  }

  render() {
    let accordionItems;
    let filterLetters;


    if (this.state.diseases) {
      accordionItems = this.state.diseases.map(disease => (
        <React.Fragment key={disease.slug}>
          <AccordionItem
            id={disease.slug}
            isOpened={this.state.openedDisease === disease.slug}
            onClick={() => this.accordionChangeHandler('openedDisease', disease.slug)}>
            <DiseaseName>{disease.name}</DiseaseName>
            <DiseaseArrow src={this.state.openedDisease === disease.slug ? arrowUpIcon : arrowDownIcon} alt="arrow" />
          </AccordionItem>
          <AccordionItemContentAnimate
            pose={this.state.openedDisease === disease.slug ? 'visible' : 'hidden'}>
            {disease.disease_section.map(aboutDisease => (
              <AboutDiseaseAccordion key={aboutDisease.id}>
                {aboutDisease.image_url && <div className="imageContainer">
                  <img src={aboutDisease.image_url} alt={aboutDisease.image_alt_text || aboutDisease.name} />
                </div>}
                <div>
                  <h2>{aboutDisease.name}</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: aboutDisease.content }}>
                  </div>
                </div>
              </AboutDiseaseAccordion>
            ))}
            <ShareLinkButton
              onClick={() => this.copyLinkToAccordionItem(disease.slug)}>
              <img src={shareLinkIcon} alt="share link"/>
              <p>{this.state.isLinkCopied ? 'Link kopiert' : 'Teilen'}</p>
            </ShareLinkButton>
            {
              disease.author
                && <AuthorInfoWrapper>
                  <H3>Autor</H3>
                  <AuthorData>
                    <AuthorImageWrapper>
                      <AuthorImage src={`${URL}${disease.author.image_url}`} alt="author" />
                    </AuthorImageWrapper>
                    <div>
                      <H5>{disease.author.name}</H5>
                      <AuthorText dangerouslySetInnerHTML={{ __html: disease.author.short_description }}></AuthorText>
                      <Link to={`/${disease.author.short_name}`}>
                        <Button>Mehr Erfahren</Button>
                      </Link>
                      <Link to={`/${disease.author.short_name}#kontakt`}>
                        <Button red>{disease.author.name} kontaktieren</Button>
                      </Link>
                    </div>
                  </AuthorData>
                </AuthorInfoWrapper>
            }
          </AccordionItemContentAnimate>
        </React.Fragment>
      ))
    }

    if (this.state.isAlphabetUpdated) {
      filterLetters = Array.from(this.state.alphabet, ([key, value]) => {
        return (
          <SingleLetter
            key={key}
            checked={this.state.checkedLetter === key}
            isDiseaseOnThatLetter={value}
            onClick={() => this.updateLetterFilter(key)}>
            <p>{key}</p>
          </SingleLetter>
        )
      })
    }

    return (
      <React.Fragment>
        <AccordionStyledWrapper id="mehrErfahren" className="scrollSection">
          <TitleSection>
            <TagSection>
              <LineLeft />
              <Tag>Erkrankungen & Therapien</Tag>
              <LineRight />
            </TagSection>
          </TitleSection>

          <WikiText>Arterien, Aneurysma, Krampfadern, Endovaskuläre Verfahren – Die Welt der Gefässmedizin ist für Patientinnen und Patienten schwer zu überblicken.  Die Spezialisten des Aorten- und GefässZentrums pflegen an dieser Stelle ein Wiki mit Wissensartikeln rund um das Themengebiet der Gefässmedizin, das Ihnen als Orientierungshilfe dienen soll. Für Fragen und über die Inhalte hinausgehende Informationsbedürfnisse stehen Ihnen die Autoren gerne zur Verfügung.</WikiText>

          <SearchInputWrapper>
            <SearchInput>
              <LoopImage src={loopIcon} />
              <input
                type="search"
                placeholder="Suchen..."
                value={this.state.searchFilter}
                onChange={this.handleChange} />
            </SearchInput>
          </SearchInputWrapper>

          <Alphabet>{filterLetters}</Alphabet>
          <Accordion>{accordionItems}</Accordion>
        </AccordionStyledWrapper>
      </React.Fragment>
    )
  }
}

export default DiseasesSection;