import React, { Component } from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Header from "../components/Header"
import Footer from '../components/footer'
import ContactSection from '../components/ContactSection'
import {initMap} from '../utils/utils'
import DiseasesSection from "../components/DiseasesSection"
import path1 from "../images/path-01.svg";


const DiseasesSectionWrapper = styled.div`
  // margin-top: 160px;
`;


class WikiPage extends Component {
  componentDidMount() {
    window.google && initMap();
  }

  render() {
    return (
      <>
        <Header />
        <Layout
          background="linear-gradient(180deg, #0e1c5d, #1c35ab, #0e1c5d)"
          images={[
            {
              path: path1,
              styles: {top: '0%', left: '50px'},
            }
          ]}
        >
          <SEO title="Gefeas-wiki" />

          <DiseasesSectionWrapper>
            <DiseasesSection />
          </DiseasesSectionWrapper>

          <ContactSection title="Aorten- und GefässZentrum" />
        </Layout>
        <Footer />
      </>
    )
  }
}

export default WikiPage
